.footer {
  background-color: #211b2e;
  padding: 40px 20px;
  color: white;
  margin-top: 40px;
  border-top: 2px solid #dedede;
}

.footerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  
}


.logo img {
  width: 400px; /* Adjust size to fit your design */
  height: auto;
  margin-bottom: 0px;
  margin-top: 10px;
}

.linksContainer {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;
}

.linksColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
}

.linksColumn h4 {
  margin-bottom: 10px;
  font-size: 1.2em;
  text-transform: uppercase;
  border-bottom: 2px solid #86cbdc;
  padding-bottom: 5px;
}

.linksColumn a {
  margin: 5px 0;
  text-decoration: none;
  color: white;
  font-size: 1em;
}

.linksColumn a:hover {
  color: #86cbdc;
}

.socialMedia {
  display: flex;
  flex-direction: column;
  align-items: center; /* Align items to the center */
  margin-bottom: 20px;
}

.socialMedia h4 {
  margin-bottom: 10px;
  font-size: 1.2em;
  text-transform: uppercase;
  border-bottom: 2px solid #86cbdc;
  padding-bottom: 5px;
}

.socialMedia .icons {
  display: flex;
}

.socialMedia .icons a {
  margin: 0 10px;
  font-size: 1.5em;
  color: white;
  text-decoration: none;
}

.socialMedia .icons a:hover {
  color: #86cbdc;
}

.newsletterSignup {
  background-color: #2e2738;
  padding: 40px 20px;
  text-align: center;
}

.textContainer {
  max-width: 600px;
  margin: 0 auto 20px auto;
}

.largeText {
  font-size: 1.5em;
  color: white;
  margin-bottom: 10px;
}

.smallText {
  font-size: 1em;
  color: #ccc;
}

.form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.form input {
  padding: 10px;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  margin-right: 10px;
  max-width: 300px;
}

.form button {
  padding: 10px 20px;
  font-size: 1em;
  background-color: #86cbdc;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.form button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.form button:hover {
  background-color: #5ab4d8;
}

.loggedInMessage {
  color: #86cbdc;
  margin-top: 10px;
}

.copyright {
  text-align: center;
  padding-top: 20px;
}

@media (max-width: 768px) {
  .linksContainer {
    flex-direction: column;
    align-items: left;
    gap: 20px;
  }
  

  .form input {
    padding: 10px;
    font-size: 0.8em;
    border: none;
    border-radius: 5px;
    margin-right: 10px;
    max-width: 300px;
  }
  
  .form button {
    padding: 10px 20px;
    font-size: 0.7em;
    background-color: #86cbdc;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .newsletterSignup {
    background-color: #2e2738;
    padding: 50px 50px;
    text-align: center;
  }
  .socialMedia .icons a {
    margin: 0 10px;
  }

  .logo img {
    width: 250px; /* Adjust size to fit your design */
    height: auto;
    margin-bottom: 10px;
  }

  .socialMedia .icons a {
    margin: 0 10px;
    font-size: 1.5em;
    color: white;
    text-decoration: none;
  }

  .linksColumn a {
    margin: 5px 0;
    text-decoration: none;
    color: white;
    font-size: 0.7em;
  }
  
  .linksColumn h4 {
    margin-bottom: 10px;
    font-size: 0.9em;
    text-transform: uppercase;
    border-bottom: 2px solid #86cbdc;
    padding-bottom: 5px;
  }

  .newsletterSignup {
    padding: 20px;
  }

  .textContainer {
    max-width: 100%;
  }

  .largeText {
    font-size: 1.2em;
  }

  .smallText {
    font-size: 0.9em;
  }
}

