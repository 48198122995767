.cryptoBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem; /* Adjust padding relative to the content */
  max-width: 1200px;
  margin: 0 auto; /* Center align within max-width */
  z-index: 1000;
  background-color: white;
}

.cryptoItem {
  display: flex;
  align-items: center;
  padding: 0.5rem; /* Adjust padding relative to the content */
  border-radius: 0.5rem; /* Adjust border radius relative to the content */
  background-color: white;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.1); /* Adjust box shadow relative to the content */
  transition: transform 0.2s ease-in-out;
}

.cryptoItem:hover {
  transform: translateY(-0.3rem); /* Adjust transform relative to the content */
}

.cryptoIcon {
  width: 4rem; /* Adjust icon size relative to the content */
  height: 4rem; /* Adjust icon size relative to the content */
  margin-right: 0.5rem; /* Adjust margin relative to the content */
}

.cryptoDetails {
  display: flex;
  flex-direction: column;
}

.cryptoName {
  font-weight: bold;
  font-size: 1rem; /* Adjust font size relative to the content */
}

.cryptoPrice {
  color: #333;
  font-size: 1rem; /* Adjust font size relative to the content */
}

.cryptoChange {
  color: white;
  padding: 0.1rem 0.3rem; /* Adjust padding relative to the content */
  border-radius: 0.3rem; /* Adjust border radius relative to the content */
  font-size: 0.6rem; /* Adjust font size relative to the content */
}

.positiveChange {
  background-color: green;
}

.negativeChange {
  background-color: red;
}

/* Media Query for Mobile */
@media (max-width: 768px) {
  .cryptoBar {
    padding: 0.5rem; /* Adjust padding for smaller screens */
  }

  .cryptoItem {
    padding: 0.4rem; /* Adjust padding for smaller screens */
  }

  .cryptoIcon {
    width: 1.2rem; /* Reduce icon size for smaller screens */
    height: 1.2rem; /* Reduce icon size for smaller screens */
    margin-right: 0.4rem; /* Adjust margin for smaller screens */
  }

  .cryptoName {
    font-size: 0.6rem; /* Reduce font size for smaller screens */
  }

  .cryptoPrice {
    font-size: 0.55rem; /* Reduce font size for smaller screens */
  }

  .cryptoChange {
    font-size: 0.55rem; /* Reduce font size for smaller screens */
    padding: 0.1rem 0.2rem; /* Adjust padding for smaller screens */
  }
}
