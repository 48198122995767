/* HeadingBar.module.css */

.headingBar {
  background-color: white;
  top: -4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -10px;
  padding: 10px 2px;
}

.headlineContainer {
  display: flex;
  overflow: hidden; /* Hide overflowing headlines */
  width: 100%; /* Adjust the width as needed */
  z-index: 2; /* Higher z-index to ensure it is above the arrow pseudo-elements */
}

.headlineContainer:hover {
  cursor: pointer; /* Change cursor to pointer on hover */
}

.headlineWrapper {
  display: flex;
  animation: scrollLeft 40s linear infinite; /* Animation for scrolling headlines */
}

.headlineWrapper.paused {
  animation-play-state: paused; /* Pause animation on hover */
}

.headline {
  margin-right: 20px; /* Space between headlines */
  white-space: nowrap; /* Prevent headlines from wrapping */
}

.headline a {
  text-decoration: none; /* Removes underline from links */
  color: inherit; /* Ensures links inherit parent text color */
}

.headline span {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 0.8em; /* Default font size */
  
}

.headline span:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Keyframes for scrolling animation */
@keyframes scrollLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Media Queries */

/* Medium screens (tablets, 768px and up) */
@media (max-width: 768px) {
  .headingBar {
    margin-top: -2px;
  }
  .arrow {
    font-size: 1.5em;
    padding: 8px 45px 8px 12px;
  }

  .arrow:after,
  .arrow:before {
    width: 30px;
    height: 100%;
  }

  .headline span {
    padding: 8px 16px;
    font-size: 0.9em;
  }
}

/* Small screens (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .arrow {
    font-size: 1.2em;
    padding: 6px 35px 6px 10px;
  }

  .arrow:after,
  .arrow:before {
    width: 25px;
    height: 90%;
  }

  .headline span {
    padding: 6px 12px;
    font-size: 0.8em;
  }
}

/* Extra small screens (phones, less than 576px) */
@media (max-width: 480px) {
  .arrow {
    font-size: 1em;
    padding: 5px 25px 5px 8px;
  }

  .arrow:after,
  .arrow:before {
    width: 20px;
    height: 80%;
  }

  .headline span {
    padding: 5px 10px;
    font-size: 0.7em;
  }
}
