.earnProgramContainertop {

  background-color: #751f87; /* Use a color close to the background in the image */
}

.earnProgramContainer {
  max-width: 1200px;
  display: flex;
  
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  padding-left: 60px;
  padding-right: 60px;
  border-radius: 8px;
  color: white;
  text-decoration: none;
  transition: transform 0.3s ease-in-out; /* Add transition for smooth scaling */
}


.earnProgramContainer:hover {
  transform: scale(1.05); /* Scale up on hover */
}

.content {
  flex: 1;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  color: #88e4ff; /* Adjust to match the title color */
}

.description {
  font-size: 1rem;
  margin: 10px 0;
}

.highlight {
  color: #00cfff; /* Adjust to match the highlighted text color */
  font-weight: bold;
}

.ctaButton {
  background-color: #f6b2c0; /* Adjust to match the button color */
  color: #441849;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  display: inline-block;
  margin-top: 10px;
}

.imageContainer {
  margin-left: 20px;
}

.image {
  border-radius: 50%;
  border: 3px solid #00cfff; /* Adjust to match the image border color */
}

/* Mobile Styles */
@media (max-width: 768px) {
  .earnProgramContainer {
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .title {
    font-size: 0.6rem;
  }

  .description {
    font-size: 0.6rem;
  }

  .ctaButton {
    font-size: 0.5rem;
    padding: 6px 12px;
  }

  .imageContainer {
    margin-left: 10px;
  }

  .image {
    width: 50px;
    height: 50px;
  }
}
