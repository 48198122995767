.newsSection {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  gap: 20px;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

.cryptoh4 {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limits the text to 2 lines */
  -webkit-box-orient: vertical;
  font-size: 16.4px;
  overflow: hidden; /* Hides overflow text */
  text-overflow: ellipsis; /* Adds ellipsis (...) for overflowed text */
  margin: 0; /* Optional: Adjust margins if necessary */
}
.sidebar {
  margin-top: 12px;
  flex: 1; /* Ensures it takes up available space */
  background: #f8f9fa;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%; /* Ensures full width by default */
  display: flex;
  flex-direction: column;
}

.featured {
  flex: 1.57;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
  width: 100%; /* Ensure featured section takes 100% width by default */
}

.pressReleases {
  flex: 1;
  background: #f8f9fa;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%; /* Ensure press releases section takes 100% width by default */
}

.featuredItem, .pressRelease, .pumpedCryptoItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  text-align: center;
}

.featuredItem {
  margin-bottom: 0px;
  
}
.cryptoImage {
  width: 100%; /* Adjust the width as needed */
  max-height: 120px; /* Maintain aspect ratio */
  max-width: 100%; /* Ensure the image doesn't overflow its container */
  border-radius: 8px; /* Rounded corners for aesthetics */
  display: block; /* Remove any inline spacing */
  margin: 0 auto; /* Center the image horizontally if needed */
}



.cryptoImage2 {
  width: 100%; /* Adjust the width as needed */
  max-height: 178px; /* Maintain aspect ratio */
  max-width: 100%; /* Ensure the image doesn't overflow its container */
  border-radius: 8px; /* Rounded corners for aesthetics */
  display: block; /* Remove any inline spacing */
  margin: 0 auto; /* Center the image horizontally if needed */
}

.pressRelease {
  max-width: 100%;
  padding: 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pressReleaseImage, .pressReleaseText {
  width: 100%;
}

.pressRelease img, .pumpedCryptoItem img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.featuredItem img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.cryptoDetails {
  margin-top: 10px;
  text-align: left; /* Ensure text is left-aligned */
  width: 100%;
}

.cryptoTitle, .cryptoTitle2 {
  font-weight: bold;
  font-size: 1em;
  margin: 10px 0;
  text-decoration: none;
  color: #1b7ab2;
}

.cryptoTitle:hover, .cryptoTitle2:hover {
  color: #0056b3;
}

.cryptoDate {
  font-size: 0.9em;
  color: #6c757d;
  margin-top: 5px; /* Add margin to separate date from other elements */
}

.cryptoh3 {
  font-size: 1.21rem;
}

.itemdate {
  font-size: 0.9em;
  color: #6c757d;
  align-self: flex-start;
  margin-top: 5px; /* Add margin to separate date from other elements */
}

.pressReleases a {
  text-align: left;
  font-size: 11px;
}

.itemdate2 {
  font-size: 0.9em;
  margin-top: 5px; /* Add margin to separate date from other elements */
  color: #6c757d;
  align-self: flex-start;
}




/* General styles for the sidebar */
.sidebar {
  flex: 1; /* Take up available space */
  background: #f8f9fa;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%; /* Full width by default */
}

/* Sidebar heading */
.sidebar h2 {
  font-size: 1.3em; /* Adjust heading size */
  margin-bottom: 20px; /* Space below heading */
  color: #333; /* Dark color for the heading */
}

/* List styles inside the sidebar */
.sidebar ul {
  list-style: none; /* Remove default list styling */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}



/* Image styles */
.cryptoImage {
  width: 100%; /* Ensure image fills the container */
  max-height: 120px; /* Maintain aspect ratio */
  object-fit: cover; /* Cover the container while maintaining aspect ratio */
  border-radius: 8px; /* Rounded corners */
  margin-bottom: 10px; /* Space below the image */
}

/* Styles for text details */
.cryptoDetails {
  text-align: left; /* Align text to the left */
}

/* Title link styles */
.cryptoTitle {
  font-weight: bold;
  font-size: 1em;
  text-decoration: none;
  color: #1b7ab2; /* Blue color for the link */
  display: block; /* Ensures the link takes full width */
  margin-bottom: 5px; /* Space below title */
}

/* Hover effect for titles */
.cryptoTitle:hover {
  color: #0056b3; /* Darker blue on hover */
}

/* Date text styles */
.cryptoDate {
  font-size: 0.9em; /* Slightly smaller font size */
  color: #6c757d; /* Gray color for the date */
  margin-top: 5px; /* Space above the date */
}

/* Skeleton loader styles (example) */
.skeletonLoader {
  width: 100%;
  height: 200px; /* Example height */
  background: #f3f3f3;
  border-radius: 8px;
  margin-bottom: 20px; /* Space between loaders */
}

@media (max-width: 768px) {
  .newsSection {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }

  .sidebar, .featured, .pressReleases {
    width: 100%;
    margin-bottom: 2px;
  }

  .cryptoTitle, .cryptoTitle2 {
    font-size: 1em;
  }

  .cryptoDate {
    font-size: 0.8em;
  }

  .pressRelease {
    flex-direction: row;
    align-items: flex-start;
    width: 80%;
  }

  .pressReleaseImage, .pressReleaseText {
    width: 10%;
  }

  .pressReleaseText {
    padding-left: 0px;
  }

  .featuredItem, .pressRelease, .pumpedCryptoItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  .featuredItem img, .pressRelease img, .pumpedCryptoItem img {
    width: 75px;
    height: 75px;
    margin-right: 10px;
  }

  .cryptoDetails {
    flex: 1;
  }

  .sidebar, .featured, .pressReleases {
    width: 100%;
    margin-bottom:-20px;
    box-shadow: none; /* Remove box shadow on mobile */
    background: none;
  }
}