.newsletterSignup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  background: linear-gradient(to top, rgb(38, 38, 59), rgb(64, 99, 124));
  width: 100%;
}

.textContainer {
  color: white;
  text-align: left;
  flex: 1;
  margin-right: 20px;
}

.largeText {
  font-size: 1.5em;
  margin: 0;
}

.smallText {
  font-size: 1em;
  margin: 0;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.form input {
  padding: 8px 15px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  width: 100%;
  max-width: 400px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.form button, .googleLogin .abcRioButton {
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1em;
  cursor: pointer;
  padding: 10px 20px;
  margin: 0 5px;
  transition: background-color 0.3s ease;
  width: 100%;
  max-width: 200px;
}

.form button {
  background-color: #1b7ab2;
}

.form button:hover {
  background-color: #00d5ff;
}

.googleLogin .abcRioButton {
  background-color: #dd4b39;
}

.googleLogin .abcRioButton:hover {
  background-color: #c23321;
}

@media (max-width: 768px) {
  .newsletterSignup {
    flex-direction: column;
  }

  .textContainer {
    text-align: center;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .formContainer {
    width: 100%;
  }

  .buttonContainer {
    flex-direction: column;
    align-items: center;
  }

  .form button, .googleLogin .abcRioButton {
    margin-bottom: 10px;
  }
}

.icons {
  display: flex;
  justify-content: center;
  gap: 15px;

  margin-top: 20px;
}

.icons a {
  color: white;
  font-size: 24px;
  transition: color 0.3s ease;
}

.icons a:hover {
  color: #0073e6;
}
