.newsSection {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: flex-start; /* Align items at the top */
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  max-width: 1200px; /* Limit maximum width */
  margin: 0 auto; /* Center the container */
}

.sidebar, .latestNews {
  flex: 1;
  margin: 0 10px;
  
}

.sidebar h2 {
  text-align: left;
}

.featured {
  
  margin: 0 10px;
  margin-top: 24px;
  flex: 2;
}

.featured img {
  max-width: 100%;
  height: auto;
}

.featured a {
  color: #333;
  text-align: left;
  margin-bottom: 10px;
  text-decoration: none; /* Ensure underline is removed */
}

.featured h2:hover {
  text-decoration: underline; /* Add underline on hover */
}




.pumpedCryptoItem {
  display: flex;
  align-items: left;
  margin-bottom: 10px;
}

.cryptoImage {
  width: 70px;
  height: 70px;
  object-fit: cover;
  margin-right: 10px;
}

.cryptoDetails {
  display: flex;
  flex-direction: column;
}

.cryptoTitle {
  font-size: 13px;
  font-weight: bold;
  text-decoration: none;
  color: #000;
  text-align: left;
}


.cryptoTitle2 {
  font-size: 11px; /* Font size for the title */
  text-decoration: none; /* Remove underline from the link */
  color: #000; /* Text color */
  line-height: 1.2; /* Adjust line height for better spacing between lines */
  text-align: left; /* Align text to the left */
  margin-bottom: 5px; /* Add spacing below each title */
}

.cryptoTitle:hover {
  text-decoration: underline;
}

.cryptoDate {
  font-size: 10px;
  text-align: left;
  color: #666;
}

.latestNews h2{
  text-align: left;
}
.latestNews ul {
  list-style-type: none;
  padding: 0;
}

.latestNews li {
  margin: 10px 0;
  text-align: left; /* Ensure the text within list items is aligned left */
}

.latestNews a {
  text-decoration: none;
  color: #007bff;
  font-size: 18px;
}

.latestNews a:hover {
  text-decoration: underline;
  color: #0056b3;
}
.cryptoDetails .cryptoTitle2 {
  color: #007bff;
  text-decoration: none;
  font-size: 15px; /* Change this to the desired font size */
}
@media (max-width: 1200px) {


  .sidebar .cryptoTitle {
    font-size: 10px;
    font-weight: bold;
    text-decoration: none;
    color: #000;
    text-align: left;
  }

  .cryptoDetails .cryptoTitle2 {
    color: #007bff;
    text-decoration: none;
    font-size: 12px; /* Change this to the desired font size */
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .newsSection {
    flex-direction: column;
    align-items: left;
    padding: 3px;
  }
  .featured img {
    width: 100%;
    height: auto;
  }
 
  

  .sidebar, .latestNews, .featured {
    margin: 10px 0;
  }



  .cryptoDetails .cryptoTitle2 {
    color: #007bff;
    text-decoration: none;
    font-size: 16px; /* Change this to the desired font size */
  }


}

/* Example: Aspect ratio box in CSS */
/* NewsSection.module.css */
.aspect-ratio-box {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  overflow: hidden;
}

.aspect-ratio-box img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}










.sidebar {
  flex: 1;
  margin: 0 10px;
  box-sizing: border-box;
  text-align: left; /* Ensure the text inside is left-aligned */
}

.sidebar h2 {
  margin-bottom: 10px; /* Add spacing below the heading */
  font-size: 1.5rem; /* Adjust font size if needed */
  font-weight: bold;
}

.sidebar ul {
  list-style-type: none; /* Remove default list styles */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.sidebar li {
  margin-bottom: 15px; /* Adjust margin between list items */
}

.pumpedCryptoItem {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.cryptoImage {
  width: 70px;
  height: 70px;
  object-fit: cover;
  margin-right: 10px;
}

.cryptoDetails {
  display: flex;
  flex-direction: column;
}

.cryptoTitle {
  font-size: 13px;
  font-weight: bold;
  text-decoration: none;
  color: #000;
  text-align: left;
}

.cryptoDate {
  font-size: 10px;
  color: #666;
}



